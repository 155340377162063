<template>
  <div class="wrapper">
    <Slider></Slider>

    <!-- Table for leaderboard data -->
    <div v-if="leaderboard && leaderboard.length">
      <table class="table table-striped table-bordered">
        <thead class="thead-light">
          <tr>
            <th>First Name</th>
            <th>MSISDN</th>
            <th>Aggregate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in leaderboard" :key="index">
            <td>{{ player.first_name }}</td>
            <td>{{ player.msisdn }}</td>
            <td>{{ player.aggregate }} %</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <!-- No data available image, centered -->
    <div v-else class="no-data-container">
      <img src="/menu/nodata.png" alt="No data available" class="no-data-image" />
    </div>
  </div>
</template>

<script>
import axios from "@/services/api";
const Slider = () => import('./Slider.vue')

export default {
  data() {
    return {
      leaderboard: []
    };
  },
  components: {
    Slider,
  },
  mounted() {
    this.fetchLeaderboard();
  },
  methods: {
    fetchLeaderboard() {
      const url = `${process.env.VUE_APP_BASE_LEADERBOARD_URL}/leaderboard`;
      axios
        .get(url)
        .then(response => {
          if (response.data) {
            this.leaderboard = response.data;
          } else {
            this.leaderboard = []; // Handle null response
          }
        })
        .catch(error => {
          console.error("Error fetching leaderboard:", error);
          this.leaderboard = []; // Set to empty array if there's an error
        });
    }
  }
};
</script>

<style scoped>
.table-striped tbody tr:nth-of-type(even) {
  color: #343a40;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Flexbox styling to center the image */
.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Full view height to vertically center */
}

.no-data-image {
  max-width: 400px;
  height: auto;
}
</style>
